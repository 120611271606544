<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>
<nb-layout>
    <nb-layout-column>
        <nb-card class="form-card">
            <nb-card-header>
                <a nbButton routerLink="#"> <nb-icon pack="eva" [icon]="'arrow-back'"
                        style="font-size: 2rem;"></nb-icon> </a>
            </nb-card-header>
            <nb-card-body>
                <nb-auth-block>
                    <h1 style="text-align: center;">Register</h1>
                    <form (ngSubmit)="register(form.value)" #form="ngForm" aria-labelledby="title">
                        <div class="form-control-group">
                            <label class="label" for="input-name">Full Name:</label>
                            <input nbInput fullWidth [(ngModel)]="form.name" #name="ngModel" name="name" id="input-name"
                                placeholder="Full Name" autofocus
                                [status]="name.dirty ? (name.invalid  ? 'danger' : 'success') : 'basic'"
                                [required]="'true'" [attr.aria-invalid]="name.invalid && name.touched ? true : null">
                            <ng-container *ngIf="name.invalid && name.touched">
                                <p class="caption status-danger" *ngIf="name.errors?.required">
                                    Name is required!
                                </p>
                            </ng-container>
                        </div>

                        <div class="form-control-group">
                            <label class="label" for="input-email">Email address:</label>
                            <input nbInput fullWidth [(ngModel)]="email" name="email" id="input-email"
                                pattern=".+@.+\..+" placeholder="Email address" autofocus [readonly]="URLHasMail">
                        </div>

                        <div class="form-control-group" *ngIf="isAdminReg">
                            <label class="label" for="input-job_title">Job Title:</label>
                            <input type="text" autocomplete="off" nbInput fullWidth [(ngModel)]="job_title" name="job_title" id="input-job_title"
                                placeholder="Job Title">
                        </div>

                        <div class="form-control-group">
                            <label class="label" for="input-password">Password:</label>
                            <input nbInput fullWidth [(ngModel)]="form.password" #password="ngModel" name="password"
                                type="password" id="input-password" placeholder="Password"
                                [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
                                [required]="'true'" [minlength]="'8'" [maxlength]="'16'"
                                [attr.aria-invalid]="password.invalid && password.touched ? true : null">
                            <ng-container *ngIf="password.invalid && password.touched ">
                                <p class="caption status-danger" *ngIf="password.errors?.required">
                                    Password is required!
                                </p>
                                <p class="caption status-danger"
                                    *ngIf="(password.errors?.minlength || password.errors?.maxlength)">
                                    Password should contains
                                    from {{ '8' }}
                                    to {{ '16' }}
                                    characters
                                </p>
                            </ng-container>
                        </div>

                        <div class="form-control-group">
                            <nb-checkbox name="terms"
                                [status]="terms.dirty ? (terms.invalid  ? 'danger' : 'success') : 'basic'" #terms
                                required>Agree to <a (click)="openModel(privacyPolicy)" style="cursor: pointer;">Privacy
                                    Policy</a> and <a (click)="openModel(termsCondition)" style="cursor: pointer;">Terms and Conditions</a></nb-checkbox>
                            <ng-container *ngIf="terms.invalid && terms.touched ">
                                <p class="caption status-danger" *ngIf="terms.errors?.required">
                                    Agree to privacy is required!
                                </p>
                            </ng-container>
                        </div>

                        <button fullWidth mat-raised-button color="primary" type="submit">
                            Register
                        </button>
                    </form>
                </nb-auth-block>
            </nb-card-body>
        </nb-card>

        <ng-template #privacyPolicy let-data let-ref="dialogRef">
            <nb-card>
                <nb-card-header>
                    <span style="display: flex;justify-content: space-between;">
                        <h5>Privacy Policy</h5> <nb-icon pack="eva" [icon]="'close'" (click)="ref.close()"
                            style="font-size: 2rem;"></nb-icon>
                    </span>
                </nb-card-header>
                <nb-card-body [innerHTML]="privacyPolicyHtml">
                </nb-card-body>
            </nb-card>
        </ng-template>

        <ng-template #termsCondition let-data let-ref="dialogRef">
            <nb-card style="height: 100%; overflow: scroll;">
                <nb-card-header>
                    <span style="display: flex;justify-content: space-between;">
                        <h5>Terms & Condition</h5> <nb-icon pack="eva" [icon]="'close'" (click)="ref.close()"
                            style="font-size: 2rem;"></nb-icon>
                    </span>
                </nb-card-header>
                <nb-card-body [innerHTML]="termsConditionHtml">
                </nb-card-body>
            </nb-card>
        </ng-template>
    </nb-layout-column>
</nb-layout>
