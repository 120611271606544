import { Component, OnInit } from "@angular/core";
import { NbMenuService, NbSidebarService } from "@nebular/theme";
import { LayoutService } from "../../../@core/utils";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";

@Component({
  selector: "ngx-one-column-layout",
  styleUrls: ["./one-column.layout.scss"],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar
        class="menu-sidebar"
        tag="menu-sidebar"
        (click)="toggleSidebar($event)"
        responsive
      >
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {
  constructor(
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private nbMenuService: NbMenuService
  ) {}
  ngOnInit() {
    this.nbMenuService.onItemSelect().subscribe((event) => {
      // Check if the clicked item has children (sub-menu items)
      if (
        (!event.item.children || event.item.children.length === 0) &&
        this.deviceIsMobile
      ) {
        // If the item does not have children, close the sidebar
        this.sidebarService.collapse("menu-sidebar");
        // this.sidebarService.toggle(false, 'menu-sidebar');
      }
    });

    document.addEventListener("visibilitychange", () => {
      console.log("Current State: ", document.visibilityState);
      if (document.visibilityState !== "hidden" && this.deviceIsMobile) {
        // alert(document.visibilityState)
        window.location.reload();
      }
    });
  }

  toggleSidebar(event): boolean {
    // if (this.deviceIsMobile) {
    //   this.nbMenuService.onItemSelect().subscribe((e) => {
    //     console.log(e)
    //   })
    //   this.sidebarService.toggle(true, "menu-sidebar");
    //   this.layoutService.changeLayoutSize();
    //   return false;
    // }

    if (this.deviceIsMobile) {
      // Check if the clicked item is a sub-menu item
      if (!event.item.children) {
        // If it's not a sub-menu item, toggle the sidebar
        this.sidebarService.toggle(true, "menu-sidebar");
        this.layoutService.changeLayoutSize();
      }
      return false;
    }
  }

  get deviceIsMobile() {
    return window.innerWidth <= 800 && window.innerHeight <= 812;
  }
}
