import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { ServiceService } from "./pages/service.service";
import { concatMap, delay, retry, retryWhen } from "rxjs/operators";

export const retryCount = 3;
export const retryWaitMilliSeconds = 1000;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public service: ServiceService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.service.isAdminLogin()) {
      const authToken = this.service.getAuthorizationToken();
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${authToken}` },
      });
    }

    return next.handle(request).pipe(
      retryWhen((error) =>
        error.pipe(
          concatMap((error, count) => {
            if (count <= retryCount && error.status == 503) {
              return of(error);
            }
            return throwError(error);
          }),
          delay(retryWaitMilliSeconds)
        )
      )
    );
  }
}
