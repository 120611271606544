import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ServiceService } from "../service.service";

@Component({
  selector: "ngx-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.scss"],
})
export class ForgetPasswordComponent  {
  constructor(private service: ServiceService) {}


  forgetPassword(value: NgForm) {
    if (value.invalid) {
      this.service.showToast("info", "", "Email is required");
      return;
    }
    this.service.forgetPassword(value.value.email).subscribe((res) => {
      if (res.success) {
        this.service.showToast("primary", "", res.message);
        value.resetForm();
      }
    });
  }
}
