import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ServiceService } from "../service.service";

@Component({
  selector: "ngx-re-verification",
  templateUrl: "./re-verification.component.html",
  styleUrls: ["./re-verification.component.scss"],
})
export class ReVerificationComponent implements OnInit {
  constructor(private service: ServiceService) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  verifyEmail(value: NgForm) {
    if (value.invalid) {
      this.service.showToast("info", "", "Email is required");
      return;
    }
    this.service.reVerifyEmail(value.value.email).subscribe((res) => {
      if (res.success) {
        this.service.showToast("primary", "", res.message);
        value.resetForm();
      }
    });
  }
}
