<nb-layout>
    <nb-layout-column>
        <nb-card class="form-card" [innerHTML]="verify_email">
            <!-- <nb-card-body>
                <div style="display: flex;flex-direction: column;align-items: center;">
                    <h3>Hooray Email Verification Is Done You will be redirected in login page</h3>
                    <img src="../../../assets/images/email-verification.gif" alt="Email Verification">
                </div>
            </nb-card-body> -->
        </nb-card>
    </nb-layout-column>
</nb-layout>