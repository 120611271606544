// Get Image Url
export const getImageLink = (value: any) => {
  return `https://www.azui.io/api/uploads/${value}`;
};

// Conversion
export const convertToInternationalCurrencySystem = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
};

// Filter with one data
export const filterWithOne = (items, value, index) => {
  return items.filter((e) => e[index] !== value);
};

// Export innerHtml variableInput
export const htmlVariableInput = (str) => {
  const regex = /{([^}]*)}/gm;
  return str.match(regex);
};

// Regex to check variable input
export const variableInput = (str) => {
  const regex = /{([^}]*)}/gm;

  let m;

  while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
      console.log(`Found match, group ${groupIndex}: ${match}`);
    });
  }

  return str;
};

// Convert Base64 to blob data url
export const createBlobUrl = (dataUrl: any) => {
  const binary = atob(dataUrl.split(",")[1]);
  const array = [];

  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  const blob = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  const blobUrl = URL.createObjectURL(blob);

  return blobUrl;
};
