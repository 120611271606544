import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-open-aikey-modal',
  templateUrl: './open-aikey-modal.component.html',
  styleUrls: ['./open-aikey-modal.component.scss']
})
export class OpenAIkeyModalComponent implements OnInit {
  loggedInUser: any;
  isDisplayfirstSection: boolean = true
  isDisplayStep: boolean = false
  constructor(private dialogRef: MatDialogRef<OpenAIkeyModalComponent>, private router: Router) { }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(localStorage.getItem('azui-user'));

    if (Date.now() > Date.parse(this.loggedInUser.completeLimit)) {
      this.isDisplayfirstSection = false
    }
  }

  close() {
    this.router.navigate(['/pages/user-setting'])
    this.dialogRef.close();
  }

}
