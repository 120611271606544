<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
></ngx-spinner>

<nb-layout>
  <nb-layout-column class="layoutColumn">
    <header id="header" class="mainheader">
      <div class="main_header_first">
        <div class="lg:w-auto whitespace-nowrap"></div>
        <div class="logo me-auto text-center">
          <a
            ><img src="../../../assets/home/white_logo.png" class="logoImg"
          /></a>
        </div>
        <div class="loginAction button xl !text-2xl !py-1.5">
          <p [routerLink]="['/login']">Login</p>
          <svg
            (click)="isDisplayLogo = !isDisplayLogo"
            class="menuIcon"
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 448 512"
          >
            <path
              d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
            />
          </svg>
        </div>
      </div>
      <div class="fixed-header" *ngIf="isDisplayLogo">
        <div class="close-icon">
          <svg
            (click)="isDisplayLogo = !isDisplayLogo"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="block h-5 w-5"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M6 18 18 6M6 6l12 12"
            ></path>
          </svg>
        </div>
        <div class="logo me-auto text-center d-flex flex-column">
          <a
            ><img src="../../../assets/home/white_logo.png" class="logoImg"
          /></a>
        </div>
        <div class="loginAction button xl !text-2xl !py-1.5">
          <p [routerLink]="['/login']">Login</p>
        </div>
      </div>
    </header>

    <main id="main" class="second_section">
      <section class="container">
        <div class="second_div">
          <!-- <h2>Explore Generative AI</h2>
          <p>
            Unlock the potential of generative AI and transform your business
          </p> -->
          <div [innerHTML]="home_page" class="w-100"></div>
          <!-- <button class="shadow-none" [routerLink]="['/register']">
            Get Started
          </button> -->
          <video
            id="homeVideo"
            class="homeVideo"
            autoplay="true"
            muted
            controls
            width="100%"
            controlsList="nodownload"
            (ended)="endedVideo()"
          >
            <source
              src="../../../assets/home/Azui Homepage.mp4"
            />
          </video>
          <button class="shadow-none" [routerLink]="['/register']">
            Get Started
          </button>
        </div>
      </section>

      <section class="third_section container">
        <!-- <div class="third_div">
          <div>
            <p>Generative AI Tools</p>
            <span>
              Our platform has ready-to-go generative AI tools that can
              understand your business. Add your team members to not only create
              capacity but also add a different perspective to idea generation
            </span>
          </div>
          <div>
            <p>Custom Solutions</p>
            <span>
              Build bots to support your internal teams, and communicate with
              them using email. Create virtual toolboxes to generate all your
              content for a process. We can create custom solutions to support
              your requirements, giving you greater control of usage and cost
            </span>
          </div>
          <div>
            <p>Consulting & Support</p>
            <span>
              Our team can work with you to find the best use cases for
              generative AI across your business. We can support you to build
              bespoke tools and unlock more valuable work for your team
            </span>
          </div>
        </div> -->
        <div   class="third_div" [innerHTML]="Home_Page_Feature"></div>
      </section>

      <!-- <section class="fouth_section container">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of feedbackData">
            <ng-template carouselSlide [id]="slide._id">
              <div class="slides">
                <h3>"{{ slide.content }}"</h3>
                <p>- {{ slide.name }}</p>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </section> -->

      <section class="fifth_section">
        <div class="main_fouth_div container">
          <div class="Fdiv" [innerHTML]="Home_Page_contact_content">
            <!-- <h3>Contact Us</h3>
            <p>Send us a message and we'll be happy to connect with you</p> -->
          </div>
          <div class="Sdiv">
            <form [formGroup]="ContactForm">
              <div class="form_first_div">
                <div>
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control shadow-none"
                    formControlName="name"
                  />
                </div>
                <div>
                  <label>E-mail</label>
                  <input
                    type="email"
                    class="form-control shadow-none"
                    formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
                  />
                  <div
                    *ngIf="submitted && f['email'].errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f['email'].errors['required']" class="w-100">
                      Email is required
                    </div>
                    <div *ngIf="f['email'].errors['email']" class="w-100">
                      Email is invalid
                    </div>
                  </div>
                </div>
              </div>
              <label>Message</label>
              <textarea
                class="form-control shadow-none"
                rows="5"
                formControlName="message"
                [ngClass]="{ 'is-invalid': submitted && f['message'].errors }"
              ></textarea>
              <div
                *ngIf="submitted && f['message'].errors"
                class="invalid-feedback"
              >
                <div *ngIf="f['message'].errors['required']">
                  Message is required
                </div>
              </div>
              <div class="last_div_form">
                <!-- <p>
                  This site is protected by reCAPTCHA and the Google Privacy
                  Policy and Terms of Service apply.
                </p> -->
                <button (click)="btnSend()">Send</button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <footer>
        <div class="logo me-auto text-center">
          <a
            ><img src="../../../assets/home/white_logo.png" class="logoImg"
          /></a>
          <p class="copyRight">© Copyright. All Rights Reserved</p>
          <button [routerLink]="['/register']" class="register_footer">
            Register
          </button>
          <div class="policyAction">
            <span (click)="openModel(privacyPolicy)">Privacy Policy</span>
            <span (click)="openModel(termsCondition)"
              >Terms and Conditions</span
            >
          </div>
        </div>
      </footer>
    </main>
  </nb-layout-column>
</nb-layout>

<ng-template #privacyPolicy let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>
      <span style="display: flex; justify-content: space-between">
        <h5>Privacy Policy</h5>
        <nb-icon
          pack="eva"
          [icon]="'close'"
          (click)="ref.close()"
          style="font-size: 2rem"
        ></nb-icon>
      </span>
    </nb-card-header>
    <nb-card-body [innerHTML]="privacyPolicyHtml"> </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #termsCondition let-data let-ref="dialogRef">
  <nb-card class="termsHome">
    <nb-card-header>
      <span style="display: flex; justify-content: space-between">
        <h5>Terms and Conditions</h5>
        <nb-icon
          pack="eva"
          [icon]="'close'"
          (click)="ref.close()"
          style="font-size: 2rem"
        ></nb-icon>
      </span>
    </nb-card-header>
    <nb-card-body [innerHTML]="termsConditionHtml"> </nb-card-body>
  </nb-card>
</ng-template>
