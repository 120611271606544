import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-signup-success',
  templateUrl: './signup-success.component.html',
  styleUrls: ['./signup-success.component.scss']
})
export class SignupSuccessComponent {

  constructor() { }

}
