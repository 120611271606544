import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NbAuthJWTToken, NbAuthService } from "@nebular/auth";
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import { Observable, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/internal/operators/catchError";
import { map } from "rxjs/internal/operators/map";
import { filter } from "rxjs/internal/operators/filter";
import { environment } from "../../environments/environment";
import { retry, tap } from "rxjs/operators";
import axios from "axios";
import { OpenAIkeyModalComponent } from "./open-aikey-modal/open-aikey-modal.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class ServiceService {
  headers = new HttpHeaders().set("Content-Type", "application/json");
  loggedInUser: any;

  constructor(
    private httpClient: HttpClient,
    public router: Router,
    private authService: NbAuthService,
    private toastrService: NbToastrService,
    public matdialog: MatDialog
  ) {
    // this.clearCache();
    this.loggedInUser = JSON.parse(localStorage.getItem("azui-user"));
  }

  // Check Is Admin Login
  isAdminLogin() {
    const token = localStorage.getItem("auth_app_token");
    // const token = this.getCookieValue('auth_app_token');
    return !!token;
  }

  public getCountry(): Observable<any> {
    return this.httpClient.get("./assets/country.json");
  }

  // this.clearCache()

  clearCache() {
    // Clear the cache storage
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }

  // Set Authorization Token
  getAuthorizationToken() {
    let token: any = localStorage.getItem("auth_app_token");
    // const token = this.getCookieValue('auth_app_token');
    // token = JSON.parse(token);
    // return token.value;
    return token;
  }

  isAuthenticated() {
    let token: any = localStorage.getItem("auth_app_token");
    // const token = this.getCookieValue('auth_app_token');
    return token ? true : false;
  }

  getUserData() {
    let token: any = localStorage.getItem("auth_app_token");
    // const token = this.getCookieValue('auth_app_token');
    const user: any = JSON.parse(localStorage.getItem("azui-user"));
    return { token, user };
  }

  // Login
  login(value) {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/login`, value)
      .pipe(
        map((res) => {
          if (res && res.token) {
            console.log("res ==> ", res);
            localStorage.setItem("azui-user", JSON.stringify(res.user));
            localStorage.setItem("auth_app_token", res.token);
            // this.setCookie('auth_app_token', res.token, 86400);
            sessionStorage.setItem(
              "additionalProfileData",
              JSON.stringify(res.subProfile)
            );
            if (res.user.role === "superadmin") {
              console.log("I am here");
              this.router.navigate(["/pages/dashboard"]);
              return;
            }
            console.log("I am not here");
            // if (res.user.role === 'business'  && !res.user?.about && res.user.status !== "Sub") {
            //   this.router.navigate(['/pages/profile']);
            // }
            // if (res.user.role === 'business'  && res.user?.about ) {
            //   this.router.navigate(['/pages/chats']);
            // }
            if (res.user.role === "business" && res.user.status !== "Sub") {
              // if user status is not sub
              if (res.user?.about) {
                this.router.navigate(["/pages/chats"]);
              } else {
                this.router.navigate(["/pages/profile"]);
              }
            } else {
              this.router.navigate(["/pages/chats"]);
            }
            if (res.user.role === "user" && res.user?.about) {
              this.router.navigate(["/pages/chats"]);
            }
            if (res.user.role === "user" && !res.user?.about) {
              this.router.navigate(["/pages/profile"]);
            }
            if (res.user.role === "custom") {
              this.router.navigate(["/pages/chats"]);
            }
          }
          if (res.success === false) {
            this.showToast("info", "", res.message);
          }
        }),
        catchError(this.handleError.bind(this))
      );
  }

  // Invite Register
  registerAsAInvite(value) {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/registerAsAInvite`, value)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Custom Register
  registerAsCustom(value) {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/registerAsCustom`, value)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Send ReVerification Email
  reVerifyEmail(email): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/auth/reVerifyEmail/${email}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Forget Password
  forgetPassword(email): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/auth/forgetPassword/${email}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Reset Password
  resetPassword(email, token, password): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/resetPassword/${email}`, {
        token,
        password,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Remove user
  removeUser(id): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/auth/removeUser/${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Block User
  blockUser(id, block, reason): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/auth/blockUser/${id}`, { block, reason })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get User List
  companyUserList() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/auth/companyUsers`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Business Register
  businessRegister(value) {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/registerAsBusiness`, value)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get All Users
  getAllUsers(page: Number): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/auth/getAllUser?page=${page}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get User Details
  getUserDetail(id): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/auth/getUserDetails/${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Verify Email by Admin
  verifyUserEmail(email) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/auth/verifyUserEmail?email=${email}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Toggle Payment Status
  togglePaymentStatus(_id, status): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/toggleUserPayment`, {
        _id,
        status,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Recover User List
  recoverUserList(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/auth/recoverUserList`)
      .pipe(
        map((res) => res.users),
        catchError(this.handleError.bind(this))
      );
  }

  // Recover User
  recoverUser(id: any): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/auth/recoverUser/${id}`, {})
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Permanently Remove
  permanentlyRemove(id: any): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/auth/permanentlyRemove/${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Check Subscription
  checkSubscription(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/subscribe/check`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Pay Directly fro Subscription
  payDirectlyForSubscription(
    amount: any,
    fees: any,
    subUser: any,
    extraToken: any
  ): Observable<any> {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/subscribe/payDirectly?amount=${amount}&fees=${fees}&subUser=${subUser}&extraToken=${extraToken}`
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Change Account Api Usage Type
  changeAccountApiUsageType(user_id, account_key_option): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/changeAccountApiUsageType`, {
        user_id,
        account_key_option,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // changeUserStatusByAdmin

  changeUserStatusByAdmin(user_id, status): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/changeUserStatusByAdmin`, {
        user_id,
        status,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get User Profile
  getUserProfile(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/auth/getUserProfile`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get User Profile
  getUserSettingProfile(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/auth/getUserSettingProfile`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get Notification Message
  getNotificationMessageList(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/help/getNotificationMessage`)
      .pipe(
        map((res) => res.data),
        retry(3),
        catchError(this.handleError.bind(this))
      );
  }

  // Update Notification Message
  updateNotificationMessage(id, message): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/help/updateNotificationMessage`, {
        id,
        message,
      })
      .pipe(
        map((res) => res),
        retry(3),
        catchError(this.handleError.bind(this))
      );
  }

  // Update User Profile
  updateUserProfile(data): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/updateProfile`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Update selected profile
  updateSelectedProfile(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/selectedProfile`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // updateSubProfile User Profile
  updateSubProfile(data): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/updateSubProfile`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Change User Password
  changeUserPassword(old_password, new_password): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/changePassword`, {
        old_password,
        new_password,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Update user bot
  updateBotByAdmin(data): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/bbb/updateBotByAdmin`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get Instance Setting
  getInstanceSetting(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/botBuilder/setting`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get Business Instance Setting
  getBusinessInstanceSettings(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/botBuilder/BusinessSetting`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Bot Builder Section */
  // Get All Bot Instances
  getAllBotInstances(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/botBuilder`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  // Get User Bot Instances
  getUserBotInstances(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/botBuilder/user`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get Admin Bot Instances
  getAdminBotInstances(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/botBuilder/admin`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get Business Bot Instance
  getBusinessBotInstance(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/botBuilder/getBusinessBotLink`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Create instance of Bot
  createBotInstance(data): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/botBuilder`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Create User instance of Bot
  createUserBotInstance(data): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/botBuilder/user`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Create Business Instance of Bot
  createBusinessBotInstance(data): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/botBuilder/createBusinessBotLink`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Update Instance of Bot
  updateBotInstance(data): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/botBuilder`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Delete instance of Bot
  deleteBotInstance(data): Observable<any> {
    return this.httpClient
      .delete<any>(
        `${environment.apiUrl}/botBuilder?id=${data.id}&deleteAllLink=${data.deleteAllLink}`
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Change Status of Bot
  changeBotStatus(bblid, status): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/botBuilder/status`, { bblid, status })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // getSubscriptionData
  getSubscriptionData(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/botBuilder/getSubscriptionData`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Duplicate Instance Create
  duplicateInstance(bblid): Observable<any> {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/botBuilder/duplicateInstance?bblid=${bblid}`
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Duplicate Instance Create by Admin
  duplicateInstanceByAdmin(bblid, name, assign_to): Observable<any> {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/botBuilder/duplicateInstanceByAdmin?bblid=${bblid}&name=${name}&assign_to=${assign_to}`
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Share Bot Instance to Users
  shareBotInstance(bblid, name, user_id): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/botBuilder/share`, {
        bblid,
        user_id,
        name,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get Users List
  getUserList(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/botBuilder/getUser`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Check Token
  checkTokenUsage(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/botBuilder/checkTokenLimit`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Fetch share bot link
  fetchShareBotLink(id): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/botBuilder/share/${id}`
    );
  }

  /** Email Template */
  // Get All Email Template
  getAllEmailTemplate(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/et`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  // Create Email Template
  createEmailTemplate(title, data, status): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/et`, { title, data, status })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Update Email Template
  updateEmailTemplate(data): Observable<any> {
    return this.httpClient.put<any>(`${environment.apiUrl}/et`, data).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  /** Site Setting */
  // Get All Site Setting
  getSiteSetting(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/ss`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  // Update Site Setting
  updateSiteSetting(_id: any, data: any): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/ss`, { _id, data })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Custom User Bot Setting API */
  // Get User Bot Setting
  getCustomUserBotSetting(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/cbs`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }
  // Get All User Bot Setting
  getAllCustomUserBotSetting(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/cbs/all`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }
  // Create User Bot Setting
  createCustomUserBotSetting(data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/cbs`, data).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }
  // Update User Bot Setting
  updateCustomUserBotSetting(id: any, data: any): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/cbs?_id=${id}`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }
  // Delete User Bot Setting
  deleteCustomUserBotSetting(id: any): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/cbs?_id=${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Setting Section */
  // Get User Bot Setting
  getUserBotSetting(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/setting`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  // Update User Bot Setting
  updateUserBotSetting(bot_account: any): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/setting`, bot_account)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Update Intro Video Setting
  updateSystemIntroVideo(bot_account: any): Observable<any> {
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/setting/updateSystemIntroVideo`,
        bot_account
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Remove Intro Video Setting
  removeSystemIntroVideo(key, type): Observable<any> {
    return this.httpClient
      .delete(
        `${environment.apiUrl}/setting/removeIntroVideo?key=${key}&type=${type}`
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // getSystemIntroVideo
  getSystemIntroVideo(key: any): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/setting/getSystemIntroVideo?key=${key}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // getSimpleSetting
  getSimpleSetting(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/setting/getSimpleSetting`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get Bot Assistant
  getBotAssistantData(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/setting/bot_assistant`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Models Section */

  // Get All Model List
  getModelList(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/bot/getModelList`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get Model List
  getAllModelList(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/chat/model`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  // Get Admin Model List
  getAdminModelList(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/chat/getAdminBotModelsList`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Upload Model List
  uploadModel(data): Observable<any> {
    const form = new FormData();
    form.append("model_name", data.name);
    form.append("model_status", data.statusType);
    form.append("model_type", data.type);
    form.append("user", data.user);
    form.append("model", data.model);
    return this.httpClient
      .post<any>(`${environment.apiUrl}/chat/uploadModel`, form)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // checkFineTuneModelStatus
  checkFineTuneModelStatus(id: any): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/chat/checkFineTuneModelStatus?id=${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Model Status Change
  modelStatusChange(id: any, status: any): Observable<any> {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/chat/modelStatusChange?id=${id}&status=${status}`
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Update Model
  updateModel(form: any): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/chat/update`, form)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Delete Model
  deleteModel(id: any, model: any): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/chat/model?id=${id}&model=${model}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get Business Model
  getBusinessModel(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/chat/getBusinessModel`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get Business Model Show
  getBusinessModelShow(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/chat/getBusinessModelShow`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get Business Model and Public
  getBusinessModelForLink(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/chat/getBusinessModelForLink`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Upload Business Model
  uploadBusinessModel(data: any): Observable<any> {
    const form = new FormData();
    form.append("model_name", data.name);
    form.append("model_status", data.statusType);
    form.append("model_type", data.type);
    form.append("model", data.model);
    return this.httpClient
      .post<any>(`${environment.apiUrl}/chat/uploadBusinessModel`, form)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Analytics Section*/
  // Get All Analytics
  getAllAnalytics(page): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/analytic?page=${page}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  getShowAnalytics(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/analytic/all`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  getUniqueUserAnalytics(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/analytic/getUniqueUserAnalytics`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Payment Section */
  generatePayment(data): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/payment/generate`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  getUserPaymentList(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/payment/list`).pipe(
      map((res) => res.list),
      catchError(this.handleError.bind(this))
    );
  }

  generateTokenPayment(amount): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/payment/generatePaymentForToken`, {
        amount,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  getAdminPaymentList(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/payment/allList`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  upgradePremium(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/botBuilder/upgradePremium`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  getStatus(id: any): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/payment/status?id=${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  checkPayment(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/payment/check`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  generateKey(id): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/botBuilder/api`, { id })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  fetchKey(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/botBuilder/api`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  updateKey(oldId, newId): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/botBuilder/api`, { oldId, newId })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  changeDashboardStatus(id): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/botBuilder/changeDashboardStatus/${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Business Bot Builder */
  // Get Business Bot
  getBusinessBot(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/bbb`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  getBotSubProfile(id: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/bbb/getBotSubProfile`, id)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get All Business Bot
  getAllBusinessBot(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/bbb/all`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  // Create Business Bot
  createBusinessBot(data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/bbb`, data).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  // Create Business Bot for sub profile
  createBotSubProfile(data: any, id: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/bbb/createBotSubProfile`, data, id)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Update Business Bot
  updateBusinessBot(data: any): Observable<any> {
    return this.httpClient.put<any>(`${environment.apiUrl}/bbb`, data).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  // Delete Business Bot
  deleteBusinessBot(id: any): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/bbb?_id=${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Duplicate Business Bot
  duplicateBusinessBot(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/bbb/duplicate`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Duplicate Business toolbox
  duplicateBusinessToolBox(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/tool/duplicate`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Share Business Bot
  shareBusinessBot(shareId, users): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/bbb/share`, { shareId, users })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Replace link with another bot and Delete Bot
  replaceAndDelete(deleteId, replaceId): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/bbb/replaceAndDelete`, {
        deleteId,
        replaceId,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Group Management System */
  // Get Group Data
  getGroupData(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/group`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  // Get Admin User Data
  getSubAdminUser(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/auth/getSubAdminAccount`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Delete sub user
  removeSubAdminUser(user_id): Observable<any> {
    return this.httpClient
      .delete<any>(
        `${environment.apiUrl}/auth/removeSubAdminUser?user_id=${user_id}`
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // updateDefaultBotAndTool
  updateDefaultBotAndTool(
    group_id: any,
    default_bot_list: any,
    default_tool_list: any,
    default_profile_list: any,
    updateAllUser: boolean
  ): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/group/updateDefaultBotAndTool`, {
        group_id,
        default_bot_list,
        default_tool_list,
        default_profile_list,
        updateAllUser,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Invite User
  inviteUser(email): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/user`, { email })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Change role from user to admin
  changeRoleToUserToAdmin(id, user): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/changeRole`, { id, user })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Change role from admin to user
  changeRoleToAdminToUser(group_id: any, id: any, user: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/changeRoleAdminToUser`, {
        _id: group_id,
        group_id: id,
        user_id: user,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Join User
  joinUser(data): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/joinUser`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Create Sub Admin
  createSubAdmin(email): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/createSubAdmin`, { email })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Get User Data
  getUserListData(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/group/user`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  // Create Group
  createGroup(group_name, users, chat_bots, tool_boxes): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group`, {
        group_name,
        users,
        chat_bots,
        tool_boxes,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Assign User to Default Group
  assignBotDefaultGroup(group_id, users): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/group/assignBotDefaultGroup`, {
        group_id,
        users,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Assign Bot To Single User
  assignBotSingle(chat_bots, group): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/assignBotSingle`, {
        chat_bots,
        group,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Assign Tool To Single User
  assignToolSingle(tool_box, group): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/assignToolSingle`, {
        tool_box,
        group,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Assign Bot to Group all Users
  assignBotToGroup(users, chat_bots): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/assignBotGroup`, {
        users,
        chat_bots,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Assign Tool to Group all Users
  assignToolToGroup(users, tool_boxes): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/assignToolGroup`, {
        users,
        tool_boxes,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Remove Single Bot From User
  removeBotSingle(group_id, bot_id): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/removeBotSingle`, {
        group_id,
        bot_id,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Remove Single Tool From User
  removeToolSingle(group_id, tool_id): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/removeToolSingle`, {
        group_id,
        tool_id,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Update Permission To Group All Bots
  updatePermissionToGroup(permission, chat_bots, tool_box): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/addPermissionGroup`, {
        permission,
        chat_bots,
        tool_box,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Update Permission To Single User
  updatePermissionToBot(permission, chat_bots): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/addPermissionSingle`, {
        permission,
        chat_bots,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Update Tool Permission To Single User
  updatePermissionToTool(permission, tool_box): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/addPermissionToolSingle`, {
        permission,
        tool_box,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Add User To Group
  addUserToGroup(
    group_id,
    group_name,
    user_id,
    selected_bots,
    selected_tool
  ): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/addUser`, {
        group_id,
        group_name,
        user_id,
        selected_bots,
        selected_tool,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // addBotToolToUser
  addBotToolToUser(email, group_id): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/addBotToolToUser`, {
        email,
        group_id,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Update Tool and Bot in user
  updateBotAndToolForUser(id, chat_bot, tool_box): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/group`, { id, chat_bot, tool_box })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Remove User From Group
  removeUserFromGroup(id, user): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/removeUser`, {
        id,
        user,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Delete Group
  deleteGroup(group_id): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/group?group_id=${group_id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** User Settings */
  // Get User Setting
  getUserSetting(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/setting/getUserSetting`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Update User Setting
  updateUserSetting(data): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/setting/userSetting`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Email Alias */

  // Create Email Alias
  createEmailAlias(bot_id): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/et/createEmailAlias`, { bot_id })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  createEmailToolbox(toolbox_id): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/et/createEmailToolbox`, { toolbox_id })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Create select profile
  selectProfile(profile_id: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/et/selectProfile`, { profile_id })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** ToolBox */

  getToolBox(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/tool`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  getToolBoxUser(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/tool`).pipe(
      map((res) => res.data),
      catchError(this.handleError.bind(this))
    );
  }

  getToolBoxForSubProfile(userId: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/tool/getToolBoxForSubProfile`, userId)
      .pipe(
        map((res) => res.data),
        catchError(this.handleError.bind(this))
      );
  }

  getCrudToolBox(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/tool/getCrud`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  createToolBox(data): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/tool`, data).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  createToolForSubProfile(data): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/tool/createToolForSubProfile`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  updateToolBox(id, data): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/tool/${id}`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  deleteToolBox(data): Observable<any> {
    return this.httpClient
      .delete<any>(
        `${environment.apiUrl}/tool/${data.id}?deleteAllTool=${data.deleteAllTool}`
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  duplicateToolBox(id): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/tool/duplicate/${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  shareToolBox(data): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/tool/shareToolBox`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  fetchShareToolBox(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/tool/fetchShareToolBox`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  changeToolBoxDashboardStatus(toolId): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/tool/dashboard/${toolId}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Help Section */

  getTicketList(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/help/ticket`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  getTicketById(id: any): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/help/ticket/${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  createTicket(form: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/help/ticket`, form)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  sendMessage(form: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/help/message`, form)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  updateTicketStatus(ticketId): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/help/status`, { ticketId })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Temporary Chat */

  generateTemporaryId(bid: any): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/bot/generateDynamicId/${bid}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  getAllTemporary(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/temporary`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  getUniqueTemporary(bid: any): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/temporary/${bid}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  getUniqueToolBox(tid: any): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/temporary/tool/${tid}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  createTemporary(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/temporary`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  createTemporaryTool(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/temporary/tool`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  updateTemporary(id: any, data: any): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/temporary/${id}`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  deleteTemporary(id: any): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/temporary/${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  createBusiness(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/temporary/createBusiness`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Utils */

  // Generate Prompt from hugging face
  generatePrompt(value: string): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/bot/generatePrompt?message=${value}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }
  // generatePrompt(value: string): Observable<any> {
  //   return this.httpClient
  //     .post<any>(
  //       `https://merve-chatgpt-prompt-generator.hf.space/run/predict`,
  //       {
  //         data: [value],
  //       }
  //     )
  //     .pipe(
  //       map((res) => res),
  //       catchError(this.handleError.bind(this))
  //     );
  // }

  // get Token Role
  getRole() {
    return this.authService
      .onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          return token.getPayload()["role"]; // here we receive a payload from the token and assigns it to our `user` variable
        }
      });
  }

  // change alias role name
  changeAliasRoleName(data: any) {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/setting/changeAliasRole`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // change user chatbotlimit
  changeUsersChatBotLimit(data: any) {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/setting/changeChatBotUsersLimit`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // change user tool box limit
  changeUsersToolBoxLimit(data: any) {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/setting/changeToolBoxUsersLimit`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // change user email chatbot limit
  changeUsersEmailChatBotLimit(data: any) {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/setting/changeEmailBotUsersLimit`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // getting alias roles
  getAliasRoles() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/setting/getAliasRole`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Share Instance Link with other users
  shareInstanceLink(id: string): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/shortUrl?id=${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // Generate Test Response for trial
  generateTestResponse(data): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/bot/generateTestZoneResponse`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Subscription Started  */
  generatePaymentForPremium(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/subscribe/generatePaymentForPremium`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  generatePaymentForPlatinum(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/subscribe/generatePaymentForPlatinum`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  requestAccess(request_access): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/subscribe/requestAccess`, {
        request_access,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  limitedPremium(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/subscribe/limitedPremium`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  limitedPlatinum(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/subscribe/limitedPlatinum`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Subscription Ended */

  /** v2 started */

  /** Bot Links API Started */

  getBotLinks(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl2}/bot`).pipe(
      map((res) => [...res.data.botInstanceLinks, ...res.data.botLinks]),
      map((res) => res.filter((e) => e.showDashboard !== false)),
      catchError(this.handleError.bind(this))
    );
  }

  getBotLinksWithOutCondition(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl2}/bot`).pipe(
      map((res) => [...res.data.botInstanceLinks, ...res.data.botLinks]),
      catchError(this.handleError.bind(this))
    );
  }

  getModifyBotLinks(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl2}/bot/getModified`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  getModifiedBotForSubProfile(userId: any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${environment.apiUrl2}/bot/getModifiedBotForSubProfile`,
        userId
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  getModifiedGroupBot(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl2}/bot/getGroupBot`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  createBotLinks(data): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl2}/bot`, data).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  createBotLinksForSubProfile(data): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl2}/bot/createBotForSubProfile`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  updateBotLink(data): Observable<any> {
    return this.httpClient.put<any>(`${environment.apiUrl2}/bot`, data).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }

  removeBotLink(id, deleteAllLink, bbid): Observable<any> {
    return this.httpClient
      .delete<any>(
        `${environment.apiUrl2}/bot?id=${id}&deleteAllLink=${deleteAllLink}&bbid=${bbid}`
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  removeGroupUser(id, user): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/group/deleteUser`, { id, user })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  updateUser(_id, name, job_title): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/updateUser`, {
        _id,
        name,
        job_title,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  duplicateBotLink(id, name, assign_to): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl2}/bot/duplicate/${id}`, {
        name,
        assign_to,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  shareBotLink(bblid, users, name): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl2}/bot/share`, { bblid, users, name })
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  getShareList(id): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl2}/bot/share/${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  /** Bot Links API Ended */

  // start feedback API
  addFeedback(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/feedback`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }
  FeedbackData(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/feedback`).pipe(
      map((res) => res),
      catchError(this.handleError.bind(this))
    );
  }
  updateFeedback(data: any): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/feedback`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }
  deleteFeedback(_id: any): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/feedback/${_id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }
  // end feedback API

  /** v2 ended */

  // Contact us
  contactUs(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/auth/contactus`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // start add additional profile

  // add data
  addAditionalProfile(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/profile/addProfile`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // get data
  getAditionalProfile(id: any): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/profile/getProfileData/${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // update data
  updateAditionalProfile(data: any): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/profile/updateProfile`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // getProfileDataForSubUser
  getProfileDataForSubUser(id: any): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/profile/getProfileDataForSubUser/${id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // getFullAccessUsers
  getFullAccessUsers(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/profile/getFullAccessUsers`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // updateProfileStatus
  updateProfileStatus(_id: any): Observable<any> {
    return this.httpClient
      .put<any>(`${environment.apiUrl}/profile/updateProfileStatus`, _id)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  // end add additional profile

  limitCompleteModal() {
    const getOpenKey = sessionStorage.getItem("isOpenAIkey");
    console.log("calledddddddddddddd ", getOpenKey);
    if (getOpenKey == "true") {
      return;
    }
    if (Date.now() > Date.parse(this.loggedInUser?.completeLimit)) {
      return this.matdialog.open(OpenAIkeyModalComponent, {
        width: "600px",
        disableClose: true,
      });
    }
  }

  showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 5000,
      hasIcon: false,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: true,
    };
    const titleContent = title ? `${title}` : "";

    this.toastrService.show(body, titleContent, config);
  }

  private handleError(error: HttpErrorResponse) {
    console.log("error", error);

    let msg = "";
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
      (msg = "An error occurred:"), error.error.message;
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
          `body was: ${error.error.error}`
      );
      msg =
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.error}`;
      this.showToast("danger", "", error.error.error);
    }

    if (error.error?.name === "TokenExpiredError") {
      // localStorage.clear();
      localStorage.removeItem("azui-user");
      localStorage.removeItem("auth_app_token");
      this.router.navigate([""]);
    }
    // if (error.statusText === "Unauthorized") {
    //   localStorage.clear();
    //   this.router.navigate([""]);
    // }
    return throwError(msg);
  }
  // setCookie(cookieName: any, cookieValue: any, maxAgeInSeconds: any) {
  //   const d = new Date();
  //   d.setTime(d.getTime() + maxAgeInSeconds * 1000); // Convert maxAge to milliseconds
  //   const expirationDate = d.toUTCString();
  //   const cookieString = `${cookieName}=${cookieValue}; expires=${expirationDate}; path=/`;
  //   document.cookie = cookieString;
  // }
  // removeCookie(cookieName: any) {
  //   const expirationDate = new Date();
  //   expirationDate.setTime(expirationDate.getTime() - 1);
  //   const cookieOptions = `expires=${expirationDate.toUTCString()}; path=/`;
  //   document.cookie = `${cookieName}=; ${cookieOptions}`;
  // }
  // getCookieValue (cookieName: any) {
  //   const cookies = document?.cookie.split(';');
  //   for (let i = 0; i < cookies.length; i++) {
  //     const cookie = cookies[i].trim();
  //     if (cookie.startsWith(cookieName + '=')) {
  //       const cookieValue = cookie.substring(cookieName.length + 1);
  //       return cookieValue;
  //     }
  //   }
  //   return null; // Return null if the cookie is not found
  // }

  uploadToolAvatar(fData: any) {
    return this.httpClient.post(`${environment.apiUrl}/tool/uploadFile`, fData);
  }

  // User Assistant Start
  createAssistant(data): Observable<any> {
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/assistant/createFileSearchAssistant`,
        data
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  getAllAssistants(): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/assistant/getAllAssistants`)
      .pipe(
        map((res) => res.data),
        catchError(this.handleError.bind(this))
      );
  }

  updateAssistant(data: any, id: any): Observable<any> {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/assistant/updateAssistant/${id}`, data)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  deleteAssistant(_id: any): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/assistant/deleteAssistant/${_id}`)
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }

  loadExistingAssistant(assistantData: any) {
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/assistant/loadExisingAssistant`,
        assistantData
      )
      .pipe(
        map((res) => res),
        catchError(this.handleError.bind(this))
      );
  }
  // end User Assistant
}
