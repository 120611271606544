<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
></ngx-spinner>
<nb-layout>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center">
      <h1 class="logo me-auto">
        <a [routerLink]="['/']"><img src="assets/img/logo.png" /></a>
      </h1>

      <nav id="navbar" class="navbar">
        <ul>
          <li>
            <a class="nav-link scrollto" [routerLink]="['/auth/login']"
              >Login</a
            >
          </li>
          <li>
            <a class="nav-link scrollto" [routerLink]="['/auth/register']"
              >Register</a
            >
          </li>
          <li>
            <a class="nav-link scrollto" [routerLink]="['/register']"
              >Business Register</a
            >
          </li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->
    </div>
  </header>
  <!-- End Header -->
  <nb-layout-column class="bg-image">
    <!--
        <main id="main">
            <div class="main-section"> -->
    <nb-card class="form-card">
      <!-- <nb-card-header>
        <a nbButton routerLink="#">
          <nb-icon
            pack="eva"
            [icon]="'arrow-back'"
            style="font-size: 2rem"
          ></nb-icon>
        </a>
      </nb-card-header> -->
      <nb-card-body class="card-bg-color">
        <div class="form_logo">
          <img src="../../../assets/images/logo_without_text.png" alt="">
        </div>
        <nb-auth-block>
          <h2 class="auth_title">Login</h2>
          <!-- <h1 style="text-align: center" class="loginTitle">
            <span
              [ngStyle]="{
                'background-color':
                  currentUrl !== 'login' ? '#192037' : 'transparent'
              }"
              >Login</span
            >
            /
            <span
            (click)="isOpenLogin()"
            [ngStyle]="{
              'background-color':
                currentUrl === 'login' ? '#192037' : 'transparent'
            }"
            >Register</span
          >
          </h1> -->
          <div class="auth_switch">
            <div class="auth_switch_buttons" [ngClass]="currentUrl !== 'login'?  'active' : ''">
              Login
            </div>
            <div class="auth_switch_buttons" (click)="isOpenLogin()" [ngClass]="currentUrl === 'login' ? 'active': ''">
              Register
            </div>
          </div>
          <form
            (ngSubmit)="login(form.value)"
            #form="ngForm"
            aria-labelledby="title"
          >
            <div class="form-control-group">
              <label class="label" for="input-email">Email address:</label>
              <input
                nbInput
                fullWidth
                class="login_input"
                [(ngModel)]="form.email"
                #email="ngModel"
                name="email"
                id="input-email"
                pattern=".+@.+\..+"
                placeholder="Enter Your Email Address"
                autofocus
                [status]="
                  email.dirty ? (email.invalid ? 'danger' : 'success') : 'basic'
                "
                [required]="'true'"
                [attr.aria-invalid]="
                  email.invalid && email.touched ? true : null
                "
              />
              <ng-container *ngIf="email.invalid && email.touched">
                <p class="caption status-danger" *ngIf="email.errors?.required">
                  Email is required!
                </p>
                <p class="caption status-danger" *ngIf="email.errors?.pattern">
                  Email should be the real one!
                </p>
              </ng-container>
            </div>

            <div class="form-control-group">
              <label class="label password-input-login" for="input-password"
                ><span>Password:</span
                ></label
              >
              <div class="position-relative">
                <input
                  nbInput
                  fullWidth
                  [(ngModel)]="form.password"
                  #password="ngModel"
                  name="password"
                  class="login_input"
                  [type]="getInputType()"
                  id="input-password"
                  placeholder="Enter Your Password"
                  [status]="
                    password.dirty
                      ? password.invalid
                        ? 'danger'
                        : 'success'
                      : 'basic'
                  "
                  [required]="'true'"
                  [minlength]="'8'"
                  [maxlength]="'16'"
                  [attr.aria-invalid]="
                    password.invalid && password.touched ? true : null
                  "
                />
                <button (click)="toggleShowPassword()" class="password_show_button" type="button">
                  <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                           pack="eva"
                           [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
                  </nb-icon>
                </button>
              </div>
              <ng-container *ngIf="password.invalid && password.touched">
                <p
                  class="caption status-danger"
                  *ngIf="password.errors?.required"
                >
                  Password is required!
                </p>
                <p
                  class="caption status-danger"
                  *ngIf="
                    password.errors?.minlength || password.errors?.maxlength
                  "
                >
                  Password should contains from {{ "8" }} to {{ "16" }}
                  characters
                </p>
              </ng-container>
              <div style="text-align: right;">
                <a [routerLink]="['/forget-password']"
                    >Forget Password?</a
                  >
              </div>
            </div>

            <span class="password-input-login">
              <div style="text-align: center;">
                <button class="login_button" type="submit">
                  Login
                </button>
              </div>
              <div style="text-align: center;margin-top: 10px;">
                <span>
                  Don't have an account?
                  <a [routerLink]="['/register']" style="color: #3EC5D5; font-weight: 900; text-decoration: none;">Register Here</a>
                </span>
              </div>
            </span>

            <span> </span>
          </form>
        </nb-auth-block>
      </nb-card-body>
    </nb-card>
    <!-- </div>
        </main> -->
  </nb-layout-column>
</nb-layout>
