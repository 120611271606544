<section class="subscription_section" *ngIf="isDisplayfirstSection">
  <div class="subscription_panel">
    <h5>We hope you've enjoyed your free access to the Azui platform.</h5>
    <h6>
      To maintain free access you'll need to load your own OpenAI API Key.
    </h6>
    <b>To obtain your own OpenAI API key, follow these steps</b>
    <div class="steps">
      <p>1. Visit the OpenAI Platform website at platform.openai.com.</p>
      <p>
        2. Sign in with your OpenAI account or create one if you don’t have an
        existing account.
      </p>
      <p>
        3. Click on your profile icon at the top-right corner and select “View
        API Keys.”
      </p>
      <p>
        4. Click the “Create New Secret Key” button to generate your new API
        key.
      </p>
    </div>
    <span>
      Once you have this, go into your settings and paste it into your API Key.
      This means your usage will be charged directly by OpenAI
    </span>
    <span>
      If you want to upgrade your account to have additional users or more of
      our features, please connect with us."
    </span>
  </div>
  <div class="text-end mt-3">
    <button (click)="close()">Close</button>
  </div>
</section>

<!-- second section -->
<section class="subscription_section" *ngIf="!isDisplayfirstSection">
  <div class="subscription_panel">
    <p class="complete">
      Your Azui platform quota is expired please load the open AI key to use all
      Azui platform features.
    </p>
    <p class="suggest" (click)="isDisplayStep = !isDisplayStep">To obtain your own OpenAI API key, follow these steps:</p>
    <div class="steps" *ngIf="isDisplayStep">
      <p>1. Visit the OpenAI Platform website at platform.openai.com.</p>
      <p>
        2. Sign in with your OpenAI account or create one if you don’t have an
        existing account.
      </p>
      <p>
        3. Click on your profile icon at the top-right corner and select “View
        API Keys.”
      </p>
      <p>
        4. Click the “Create New Secret Key” button to generate your new API
        key.
      </p>
    </div>
  </div>
  <div class="text-end d-flex mt-3">
    <button (click)="close()">Add Open AI key</button>
    <button class="ms-2"><a href="https://platform.openai.com/account/api-keys" target="_blank">Get Open AI key</a></button>
  </div>
</section>
