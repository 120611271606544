export const environment = {
  production: false,
  apiUrl: "https://dev.azui.io/api/v1",
  apiUrl2: "https://dev.azui.io/api/v2",
  apiSimpleUrl: "https://dev.azui.io",
  fileUrl: "https://dev.azui.io",
  botUrl: "https://dev-bot.azui.io",
  recaptcha: {
    siteKey: '6LdPdiYqAAAAAOE30Pm6iZ-CqhHl4u4ZdG7jkh64',
  },
};
