import { Component, OnInit, TemplateRef } from "@angular/core";
import { ErrorStateMatcher } from "@angular/material/core";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { ServiceService } from "../service.service";
import { Router } from "@angular/router";
import { NbDialogService } from "@nebular/theme";
import { DomSanitizer } from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";
import { ReCaptchaV3Service } from "ng-recaptcha";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "ngx-register-business",
  templateUrl: "./register-business.component.html",
  styleUrls: ["./register-business.component.scss"],
})
export class RegisterBusinessComponent implements OnInit {
  email: any;
  password: any;
  localItem: any;
  privacyPolicyHtml: any;
  termsConditionHtml: any;
  currentUrl: any
  constructor(
    private service: ServiceService,
    private router: Router,
    private dialogService: NbDialogService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) { }

  ngOnInit(): void {
    this.currentUrl = this.router.url
    this.getLocalStorageData();
    this.service.getSiteSetting().subscribe((res) => {
      if (res.success) {
        this.privacyPolicyHtml = res.data.filter(
          (e) => e._id === "642135e677ea22a113b68556"
        )[0].data;
        this.termsConditionHtml = res.data.filter(
          (e) => e._id === "6421374277ea22a113b68557"
        )[0].data;
        this.privacyPolicyHtml = this.privacyPolicyHtml
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&amp;/g, "&");
        this.termsConditionHtml = this.termsConditionHtml
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&amp;/g, "&");
        this.privacyPolicyHtml = this.sanitizer.bypassSecurityTrustHtml(
          this.privacyPolicyHtml
        );
        this.termsConditionHtml = this.sanitizer.bypassSecurityTrustHtml(
          this.termsConditionHtml
        );
      }
    });
  }

  getLocalStorageData() {
    let parse = JSON.parse(localStorage.getItem("register-azui-item"));
    if (parse === null) {
      this.localItem = { role: "", status: "" };
      return;
    }
    this.localItem = parse;
  }

  selected(value) {
    this.localItem.role === value;
  }

  register(value) {
    value.role = "business";

    value.status = "Free";
    value.recaptchaToken = "";

    if (value.terms === undefined || value.terms === false) {
      this.service.showToast(
        "info",
        "",
        "Please agree to the Privacy Policy and Terms and Conditions to complete your registration"
      );
      return;
    }

    this.spinner.show();

    this.recaptchaV3Service.execute('registerBusiness').subscribe((token:any) => {
      if(token){
        value.recaptchaToken = token;

        this.service.businessRegister(value).subscribe(
          (res) => {
            if (res.success) {
              this.spinner.hide();
              // this.service.showToast("primary", "", res.message);
              this.router.navigate(["/signup-success"]);
            }
          },
          (error) => {
            console.log("error", error);
            this.spinner.hide();
          }
        );
      }
    });
  }

  openModel(contentTemplate: TemplateRef<any>) {
    this.dialogService.open(contentTemplate, {});
  }

  matcher = new MyErrorStateMatcher();

  isOpenLogin() {
    this.router.navigate(['login'])
  }
}
