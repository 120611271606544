<nb-layout>
    <nb-layout-column>

        <div class="row">
            <div class="col-md-12">
                <h2>Thanks for registering. We've just sent you an email verification link, please complete that to
                    receive
                    access to the system.</h2>
            </div>
        </div>

    </nb-layout-column>
</nb-layout>