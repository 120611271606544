import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ServiceService } from "../service.service";

@Component({
  selector: "ngx-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  constructor(private service: ServiceService) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  changePassword(value: NgForm) {
    if (value.invalid) {
      this.service.showToast("info", "", "Password is required");
      return;
    }

    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: string) => searchParams.get(prop),
    });

    if (params.email === null || params.token === null) {
      this.service.showToast("info", "", "Invalid Url");
      return;
    }

    this.service
      .resetPassword(params.email, params.token, value.value.password)
      .subscribe((res) => {
        if (res.success) {
          this.service.showToast("primary", "", res.message);
        }
      });
  }
}
