import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { ServiceService } from './pages/service.service';

@Injectable({
  providedIn: 'root'
})
export class LoginauthGuard implements CanActivate {
  constructor(
    private authService: NbAuthService,
    private service: ServiceService,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let checkToken = this.service.isAuthenticated();
    console.log("checkToken", checkToken)
    if (checkToken) {
      return this.router.navigate(["/pages/chats"]);
    }
    // else {
    //   window.location.href = 'https://home.azui.io/'
    // }

    return !checkToken;
  }

}
