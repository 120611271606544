import { Component, OnInit } from "@angular/core";
import { ServiceService } from "../service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
@Component({
  selector: "ngx-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  currentUrl:any
  showPassword = false;
  constructor(
    private service: ServiceService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.currentUrl = this.router.url
    console.log("this.currentUrl", this.currentUrl)
  }

  login(value) {
    // return;
    this.spinner.show();
    this.service.login(value).subscribe(
      (res) => {
        this.spinner.hide();

      },
      (err) => {
        this.spinner.hide();
        console.log("err", err);
      }
    );
  }

  isOpenLogin() {
    this.router.navigate(['register'])
  }
  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
