<div class="header-container">
  <div class="logo-container" style="width: auto">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a
      class="logo"
      *ngIf="account_type === 'Sub'"
      href="#"
      (click)="navigateHome()"
      `
      style="font-size: 1rem"
      >{{ account_type }} account - {{ business_name }}
    </a>
    <a
      class="logo"
      *ngIf="account_type !== 'Sub'"
      href="#"
      (click)="navigateHome()"
      style="font-size: 1rem"
      >{{ business_name }}
    </a>
  </div>
<!--  <nb-select-->
<!--    [selected]="currentTheme"-->
<!--    (selectedChange)="changeTheme($event)"-->
<!--    status="primary"-->
<!--  >-->
<!--    <nb-option *ngFor="let theme of themes" [value]="theme.value">-->
<!--      {{ theme.name }}</nb-option-->
<!--    >-->
<!--  </nb-select>-->
  &nbsp;
  <button
    nbButton
    *ngIf="user?.status === 'Free'"
    (click)="navigateSubscription()"
  >
    Upgrade
  </button>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action">
      <!-- <p *ngIf="additonalProfileData">
        {{ additonalProfileData?.profile_name }}
      </p> -->
<!--      <nb-user-->
<!--        *ngIf="user?.profilePic"-->
<!--        [nbContextMenu]="userMenu"-->
<!--        [picture]="backend_file_api+'/'+user?.profilePic"-->
<!--        [name]="user?.name"-->
<!--        nbContextMenuTag="my-context-menu"-->
<!--      >-->
<!--      </nb-user>-->
      <nb-user
        [nbContextMenu]="userMenu"
        [name]="user?.name"
        nbContextMenuTag="my-context-menu"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

<ng-template #subscription let-data let-ref="dialogRef">
  <section class="subscription_section">
    <header>
      {{ subscriptionData.message }}
      <button type="button" class="close" data-dismiss="modal" (click)="ref.close()" aria-label="Close">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="15" height="15" x="0" y="0" viewBox="0 0 329.269 329" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M194.8 164.77 323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0" fill="#ffffff" opacity="1" data-original="#000000" class=""></path></g></svg>
      </button>
    </header>

    <div class="subscription_panel">
      <div class="subscription_panel_inner"><span>Monthly Fee :</span>${{ subscriptionData.monthly_fee }}</div>
      <div class="subscription_panel_inner"><span>Sub User Fee :</span>${{ subscriptionData.subUserFee }}</div>
      <div class="subscription_panel_inner"><span>Extra Token Fee :</span>${{ subscriptionData.extraTokenUsage }}</div>
      <div class="subscription_panel_btn">
        <button nbButton (click)="payDirectly(subscriptionData); ref.close()">
          Pay {{ subscriptionData?.amount }}
        </button>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #remainPayment let-data let-ref="dialogRef">
  <section class="subscription_section">
    <div class="subscription_panel">
      <p>Monthly payment is due</p>
    </div>
  </section>
</ng-template>
