<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
></ngx-spinner>
<nb-layout>
  <nb-layout-column class="bg-image">
    <nb-card class="form-card">
      <!-- <nb-card-header>
        <a nbButton routerLink="#">
          <nb-icon
            pack="eva"
            [icon]="'arrow-back'"
            style="font-size: 2rem"
          ></nb-icon>
        </a>
      </nb-card-header> -->
      <nb-card-body class="card-bg-color">
        <nb-auth-block>
          <div class="form_logo">
            <img src="../../../assets/images/logo_without_text.png" alt="">
          </div>
          <!-- <p style="color: #f73e3e; font-size: 13px">Note: We are not accepting new members currently, please
                        contact us if you'd like to hear more.</p> -->
          <div>
            <!-- <h1 style="text-align: center" class="registerrTitle">
              <span
                [ngStyle]="{
                  'background-color':
                    currentUrl !== 'login' ? '#192037' : 'transparent'
                }"
                >Register</span
              >
              /
              <span
                (click)="isOpenLogin()"
                [ngStyle]="{
                  'background-color':
                    currentUrl === 'login' ? '#192037' : 'transparent'
                }"
                >Login</span
              >
            </h1> -->
            <h2 class="auth_title">Register</h2>


            <div class="auth_switch">
              <div class="auth_switch_buttons" (click)="isOpenLogin()" [ngClass]="currentUrl === 'register'?  'active' : ''">
                Login
              </div>
              <div class="auth_switch_buttons" [ngClass]="currentUrl !== 'register' ? 'active': ''">
                Register
              </div>
            </div>
          </div>
          <form
            (ngSubmit)="register(form.value)"
            #form="ngForm"
            aria-labelledby="title"
          >
            <div class="form-control-group">
              <label class="label" for="input-name">Full Name:</label>
              <input
                nbInput
                fullWidth
                [(ngModel)]="form.name"
                #name="ngModel"
                name="name"
                id="input-name"
                class="register_input"
                placeholder="Full Name"
                autofocus
                autocomplete="off"
                [status]="
                  name.dirty ? (name.invalid ? 'danger' : 'success') : 'basic'
                "
                [required]="'true'"
                [attr.aria-invalid]="name.invalid && name.touched ? true : null"
              />
              <ng-container *ngIf="name.invalid && name.touched">
                <p class="caption status-danger" *ngIf="name.errors?.required">
                  Name is required!
                </p>
              </ng-container>
            </div>

            <div class="form-control-group">
              <label class="label" for="input-name">Business Name:</label>
              <input
                nbInput
                fullWidth
                [(ngModel)]="form.business_name"
                #business_name="ngModel"
                name="business_name"
                class="register_input"
                id="input-name"
                placeholder="Business Name"
                autofocus
                [status]="
                  business_name.dirty
                    ? business_name.invalid
                      ? 'danger'
                      : 'success'
                    : 'basic'
                "
                [required]="'true'"
                autocomplete="off"
                [attr.aria-invalid]="
                  business_name.invalid && business_name.touched ? true : null
                "
              />
              <ng-container
                *ngIf="business_name.invalid && business_name.touched"
              >
                <p
                  class="caption status-danger"
                  *ngIf="business_name.errors?.required"
                >
                  Business name is required!
                </p>
              </ng-container>
            </div>

            <div class="form-control-group">
              <label class="label" for="input-email">Email address:</label>
              <input
                nbInput
                fullWidth
                type="email"
                [(ngModel)]="form.email"
                #email="ngModel"
                name="email"
                class="register_input"
                id="input-email"
                placeholder="Email address"
                autofocus
                autocomplete="off"
                [status]="
                  email.dirty ? (email.invalid ? 'danger' : 'success') : 'basic'
                "
                [required]="'true'"
                [attr.aria-invalid]="
                  email.invalid && email.touched ? true : null
                "
              />
              <ng-container *ngIf="email.invalid && email.touched">
                <p class="caption status-danger" *ngIf="email.errors?.required">
                  Email is required!
                </p>
                <!-- <p class="caption status-danger" *ngIf="email.errors?.pattern">
                                    Email should be the real one!
                                </p> -->
              </ng-container>
            </div>

            <div class="form-control-group">
              <label class="label" for="input-password">Password:</label>
              <input
                nbInput
                fullWidth
                [(ngModel)]="form.password"
                #password="ngModel"
                name="password"
                class="register_input"
                type="password"
                id="input-password"
                placeholder="Password"
                [status]="
                  password.dirty
                    ? password.invalid
                      ? 'danger'
                      : 'success'
                    : 'basic'
                "
                [required]="'true'"
                [minlength]="'8'"
                [maxlength]="'16'"
                autocomplete="off"
                [attr.aria-invalid]="
                  password.invalid && password.touched ? true : null
                "
              />
              <ng-container *ngIf="password.invalid && password.touched">
                <p
                  class="caption status-danger"
                  *ngIf="password.errors?.required"
                >
                  Password is required!
                </p>
                <p
                  class="caption status-danger"
                  *ngIf="
                    password.errors?.minlength || password.errors?.maxlength
                  "
                >
                  Password should contains from {{ "8" }} to {{ "16" }}
                  characters
                </p>
              </ng-container>
            </div>

            <div class="form-control-group">
              <nb-checkbox
                name="terms"
                [status]="
                  terms.dirty ? (terms.invalid ? 'danger' : 'success') : 'basic'
                "
                #terms
                [(ngModel)]="form.term"
                required
                >Agree to
                <a (click)="openModel(privacyPolicy)" style="cursor: pointer;color: #1273EE;"
                  >Privacy Policy</a
                >
                and
                <a (click)="openModel(termsCondition)" style="cursor: pointer;color: #1273EE;"
                  >Terms and Conditions</a
                ></nb-checkbox
              >
              <!-- <a class="forgot-password" routerLink="../request-password">Forgot Password?</a> -->
              <ng-container *ngIf="terms.invalid && terms.touched">
                <p class="caption status-danger" *ngIf="terms.errors?.required">
                  Agree to privacy is required!
                </p>
              </ng-container>
            </div>
            <div class="button_group">
              <button class="register_button" type="submit">
                Register Now
              </button>
              <span>
                If you have already account <a [routerLink]="['/login']"  style="color: #3EC5D5; font-weight: 900; text-decoration: none;">Login here</a>
              </span>
            </div>
          </form>
        </nb-auth-block>
      </nb-card-body>
    </nb-card>

    <ng-template #privacyPolicy let-data let-ref="dialogRef">
      <nb-card>
        <nb-card-header>
          <span style="display: flex; justify-content: space-between">
            <h5>Privacy Policy</h5>
            <nb-icon
              pack="eva"
              [icon]="'close'"
              (click)="ref.close()"
              style="font-size: 2rem"
            ></nb-icon>
          </span>
        </nb-card-header>
        <nb-card-body [innerHTML]="privacyPolicyHtml"> </nb-card-body>
      </nb-card>
    </ng-template>

    <ng-template #termsCondition let-data let-ref="dialogRef">
      <nb-card class="tremsRegister">
        <nb-card-header>
          <span style="display: flex; justify-content: space-between">
            <h5>Terms & Conditions</h5>
            <nb-icon
              pack="eva"
              [icon]="'close'"
              (click)="ref.close()"
              style="font-size: 2rem"
            ></nb-icon>
          </span>
        </nb-card-header>
        <nb-card-body [innerHTML]="termsConditionHtml"> </nb-card-body>
      </nb-card>
    </ng-template>
  </nb-layout-column>
</nb-layout>
