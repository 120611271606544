<nb-layout>
    <nb-layout-column>
        <nb-card class="form-card">
            <nb-card-header>
                <a nbButton routerLink="#"> <nb-icon pack="eva" [icon]="'arrow-back'"
                        style="font-size: 2rem;"></nb-icon> </a>
            </nb-card-header>
            <nb-card-body>
                <nb-auth-block>
                    <h1 style="text-align: center;">Reset Password</h1>
                    <form #form="ngForm" aria-labelledby="title">
                        <div class="form-control-group">
                            <label class="label" for="input-password">Password:</label>
                            <input nbInput fullWidth [(ngModel)]="form.password" #password="ngModel" name="password"
                                type="password" id="input-password" placeholder="Password"
                                [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
                                [required]="'true'" [minlength]="'8'" [maxlength]="'16'"
                                [attr.aria-invalid]="password.invalid && password.touched ? true : null">
                            <ng-container *ngIf="password.invalid && password.touched ">
                                <p class="caption status-danger" *ngIf="password.errors?.required">
                                    Password is required!
                                </p>
                                <p class="caption status-danger"
                                    *ngIf="(password.errors?.minlength || password.errors?.maxlength)">
                                    Password should contains
                                    from {{ '8' }}
                                    to {{ '16' }}
                                    characters
                                </p>
                            </ng-container>
                        </div>

                        <div class="form-control-group">
                            <label class="label" for="input-password">Confirm Password:</label>
                            <input nbInput fullWidth [(ngModel)]="form.cpassword" #cpassword="ngModel" name="cpassword"
                                type="password" id="input-password" placeholder="Password"
                                [status]="cpassword.dirty ? (cpassword.invalid  ? 'danger' : 'success') : 'basic'"
                                [required]="'true'" [minlength]="'8'" [maxlength]="'16'"
                                [attr.aria-invalid]="cpassword.invalid && cpassword.touched ? true : null">
                            <ng-container *ngIf="cpassword.invalid && cpassword.touched ">
                                <p class="caption status-danger" *ngIf="password.errors?.required">
                                    Confirm Password is required!
                                </p>
                                <p class="caption status-danger" *ngIf="cpassword.value !== password.value">
                                    Confirm Password should be same as Password
                                </p>
                            </ng-container>
                        </div>

                        <button fullWidth mat-raised-button color="primary" type="button"
                            (click)="changePassword(form)">
                            Submit
                        </button>
                    </form>
                </nb-auth-block>
            </nb-card-body>
        </nb-card>
    </nb-layout-column>
</nb-layout>