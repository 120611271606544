import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  NbDialogService,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { LayoutService } from "../../../@core/utils";
import { filter, map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { ServiceService } from "../../../pages/service.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  business_name: any;
  backend_file_api: string = environment.fileUrl
  account_type: any;
  additonalProfileData: any;

  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    // {
    //   value: "cosmic",
    //   name: "Cosmic",
    // },
    // {
    //   value: "corporate",
    //   name: "Corporate",
    // },
  ];

  currentTheme = "dark";

  userMenu = [{ title: "Profile" }, { title: "Log out" }];
  subscriptionData: any;

  @ViewChild("subscription", { static: true })
  subscription: TemplateRef<HTMLElement>;
  @ViewChild("remainPayment", { static: true })
  remainPayment: TemplateRef<HTMLElement>;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private service: ServiceService,
    private dialogService: NbDialogService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.additonalProfileData = sessionStorage.getItem("additionalProfileData") == 'undefined' ? null : JSON.parse(sessionStorage.getItem("additionalProfileData"))

    this.currentTheme = this.themeService.currentTheme;

    if (this.service.getUserData().token) {
      this.user = this.service.getUserData().user;
      console.log(this.user)
      this.service.getUserProfile().subscribe((res) => {
        if (res.success) {
          this.business_name = res.user_detail.business_name;
          this.account_type = res.user_detail?.sub_account
            ? res.user_detail.sub_account
            : res.user_detail.status;
        }
      });
      if (["Premium", "Platinum"].includes(this.user.status)) {
        this.service.checkSubscription().subscribe((res) => {
          if (res.success) {
            if (res.subscription) {
              this.dialogService.open(this.subscription);
              this.subscriptionData = res;
            }
          }
        });
      }

      // if (this.user.payment) {
      //   this.dialogService.open(this.remainPayment)
      // }
    }

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === "my-context-menu"),
        map(({ item: { title } }) => title)
      )
      .subscribe((title) => {
        if (title === "Profile") {
          this.router.navigate(["/pages/profile"]);
          return;
        }
        if (title === "Log out") {
          localStorage.removeItem("azui-user");
          localStorage.removeItem("auth_app_token");
          sessionStorage.clear();
          this.router.navigate(["/login"]);
          setTimeout(() => {
            document.body.style.overflow = "auto";
          }, 100);
        }
      });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();
    this.cdr.markForCheck ()
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  navigateSubscription() {
    this.router.navigate(["/pages/subscription"]);
  }

  payDirectly(data) {
    const { monthly_fee, subUserFee, extraTokenUsage, amount } = data;

    this.service
      .payDirectlyForSubscription(
        amount,
        monthly_fee,
        subUserFee,
        extraTokenUsage
      )
      .subscribe((res) => {
        console.log("res", res);
        if (res.success) {
          alert("You will be redirected to new tab for payment");
          window.open(res.link, "_blank");
        }
      });
  }
}
