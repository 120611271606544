import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "../service.service";
import { convertToInternationalCurrencySystem as cti } from "../utils";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../../environments/environment.prod";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NbDialogService } from "@nebular/theme";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "ngx-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
})
export class HomepageComponent implements OnInit {
  botSetting: any;
  welcomeAzui: any;
  home_page: any
  Home_Page_Feature: any;
  Home_Page_contact_content: any;
  buildBot: any;
  simpleSetting: any;
  videoDescription: any;
  isDisplayLogo: boolean = false;
  privacyPolicyHtml: any;
  termsConditionHtml: any;
  submitted: any
  ContactForm: FormGroup
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    autoplayTimeout: 5000,
    navText: ['', ''],
    items: 1, // Set items to 1 to always display one slide
    // responsive: {
    //   0: {
    //     items: 1
    //   },
    //   390: {
    //     items: 1
    //   },
    //   400: {
    //     items: 1
    //   },
    //   740: {
    //     items: 1
    //   },
    //   940: {
    //     items: 1
    //   },
    //   1024: {
    //     items: 1
    //   },
    //   // 2160: {
    //   //   items: 1
    //   // }
    // },
    nav: true
  }
  feedbackData: any = []

  constructor(
    public service: ServiceService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private dialogService: NbDialogService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.getFeedbackData();
    this.ContactForm = this.fb.group(
      {
        name: [''],
        message: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
      }
    );

    this.service.getUserBotSetting().subscribe((res: any) => {
      if (res.success) {
        this.botSetting = res.data.bot_account;
      }
    });
    this.service.getSiteSetting().subscribe((res: any) => {
      window.dispatchEvent(new Event("resize"));
      this.home_page = res.data.filter(
        (e) => e.title === "Home-Page-Title"
      )[0].data;
      this.Home_Page_Feature = res.data.filter(
        (e) => e.title === "Home-Page-Feature"
      )[0].data;
      this.Home_Page_contact_content = res.data.filter(
        (e) => e.title === "Home-contactus-content"
      )[0].data;
      this.welcomeAzui = res.data.filter(
        (e) => e.title === "Welcome-Azui"
      )[0].data;
      this.privacyPolicyHtml = res.data.filter(
        (e) => e.title === "Privacy-Policy"
      )[0].data;
      this.buildBot = res.data.filter((e) => e.title === "Build-Bot")[0].data;
      this.videoDescription = res.data.filter(
        (e) => e.title === "Video-Description"
      )[0].data;
      this.termsConditionHtml = res.data.filter(
        (e) => e._id === "6421374277ea22a113b68557"
      )[0].data;
      this.welcomeAzui = this.welcomeAzui
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");
      this.buildBot = this.buildBot
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");
      this.videoDescription = this.videoDescription
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");
      this.privacyPolicyHtml = this.privacyPolicyHtml
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");
      this.termsConditionHtml = this.termsConditionHtml
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");
      this.home_page = this.home_page
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");
      this.Home_Page_Feature = this.Home_Page_Feature
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");
      this.Home_Page_contact_content = this.Home_Page_contact_content
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");
      this.privacyPolicyHtml = this.sanitizer.bypassSecurityTrustHtml(
        this.privacyPolicyHtml
      );
      this.welcomeAzui = this.sanitizer.bypassSecurityTrustHtml(
        this.welcomeAzui
      );
      this.termsConditionHtml = this.sanitizer.bypassSecurityTrustHtml(
        this.termsConditionHtml
      );
      this.home_page = this.sanitizer.bypassSecurityTrustHtml(
        this.home_page
      );
      this.Home_Page_Feature = this.sanitizer.bypassSecurityTrustHtml(
        this.Home_Page_Feature
      );
      this.Home_Page_contact_content = this.sanitizer.bypassSecurityTrustHtml(
        this.Home_Page_contact_content
      );
      this.buildBot = this.sanitizer.bypassSecurityTrustHtml(this.buildBot);
      this.videoDescription = this.sanitizer.bypassSecurityTrustHtml(
        this.videoDescription
      );
    });
    this.service.getSimpleSetting().subscribe((res: any) => {
      if (res.success) {
        this.simpleSetting = res.data;
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.ContactForm.controls;
  }

  subscription(role: any, status: any) {
    localStorage.setItem(
      "register-azui-item",
      JSON.stringify({ role, status })
    );
    this.router.navigate(["/register"]);
  }

  convertToInternationalCurrencySystem(labelValue) {
    if (!labelValue) {
      return "";
    }
    return cti(labelValue);
  }

  renderVideo(video) {
    return environment.apiSimpleUrl + "/api/uploads/gif/" + video;
  }

  isGif(link) {
    if (link.includes(".gif")) {
      return true;
    }
    return false;
  }

  openModel(contentTemplate: TemplateRef<any>) {
    this.dialogService.open(contentTemplate, {});
  }

  btnSend() {
    this.spinner.show();
    this.submitted = true;

    if (this.ContactForm.invalid) {
      return;
    }
    this.service.contactUs(this.ContactForm.value).subscribe({
      next: (res) => {
        console.log("res", res)
        this.spinner.hide();
        this.ContactForm.reset();
        Object.keys(this.ContactForm.controls).forEach(key => {
          this.ContactForm.get(key).setErrors(null);
        });
        this.service.showToast("primary", "", "Thank you for contacting us!");
      },
      error: (err) => {
        this.spinner.hide();
        console.log("err", err)
      }
    })
    console.log("fomr", this.ContactForm.value);
  }
  endedVideo() {
    let demo: any = document.getElementById('homeVideo') as HTMLElement
    demo.currentTime = 0;
    console.log("demo", demo)
  }

  getFeedbackData() {
    this.service.FeedbackData().subscribe({
      next: (res) => {
        this.feedbackData = res.data;
      },
      error: (error) => {
        console.log("error", error)
      }
    })
  }
}
