import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "../service.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "ngx-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.scss"],
})
export class VerifyEmailComponent implements OnInit {
  verify_email: any;
  constructor(
    public service: ServiceService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.service.getSiteSetting().subscribe((res: any) => {
      if (res.success) {
        this.verify_email = res.data.filter(
          (e) => e.title === "Verify-Email"
        )[0].data;
        this.verify_email = this.verify_email
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&amp;/g, "&");
        this.verify_email = this.sanitizer.bypassSecurityTrustHtml(
          this.verify_email
        );
      }
    });
    setTimeout(() => {
      this.router.navigate(["/login"]);
    }, 5000); //5s
  }
}
