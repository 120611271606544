<nb-layout>
    <nb-layout-column>
        <nb-card class="form-card">
            <nb-card-header>
                <a nbButton routerLink="#"> <nb-icon pack="eva" [icon]="'arrow-back'"
                        style="font-size: 2rem;"></nb-icon> </a>
            </nb-card-header>
            <nb-card-body>
                <nb-auth-block>
                    <h1 style="text-align: center;">Forget Password</h1>
                    <p style="text-align: center;">Enter your email address and We will send you mail with link</p>
                    <form #form="ngForm" aria-labelledby="title">
                        <div class="form-control-group">
                            <label class="label" for="input-email">Email address:</label>
                            <input nbInput fullWidth type="email" [(ngModel)]="form.email" #email="ngModel" name="email"
                                id="input-email" placeholder="Email address" autofocus
                                [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
                                [required]="'true'" [attr.aria-invalid]="email.invalid && email.touched ? true : null">
                            <ng-container *ngIf="email.invalid && email.touched">
                                <p class="caption status-danger" *ngIf="email.errors?.required">
                                    Email is required!
                                </p>
                            </ng-container>
                        </div>

                        <button fullWidth mat-raised-button color="primary" type="button"
                            (click)="forgetPassword(form)">
                            Submit
                        </button>
                    </form>
                </nb-auth-block>
            </nb-card-body>
        </nb-card>
    </nb-layout-column>
</nb-layout>