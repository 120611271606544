import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from "@nebular/auth";
import { AuthGuardGuard } from "./auth-guard.guard";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { RegisterBusinessComponent } from "./pages/register-business/register-business.component";
import { LoginComponent } from "./pages/login/login.component";
import { VerifyEmailComponent } from "./pages/verify-email/verify-email.component";
import { InviteUserRegistrationComponent } from "./pages/invite-user-registration/invite-user-registration.component";
import { ReVerificationComponent } from "./pages/re-verification/re-verification.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { ForgetPasswordComponent } from "./pages/forget-password/forget-password.component";
import { SignupSuccessComponent } from "./pages/signup-success/signup-success.component";
import { UserGuardGuard } from "./pages/user-guard.guard";
import { LoginauthGuard } from "./loginauth.guard";

export const routes: Routes = [
  {
    path: "pages",
    canActivate: [AuthGuardGuard],
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "auth",
    component: NbAuthComponent,
    children: [
      {
        path: "",
        component: NbLoginComponent,
      },
      {
        path: "login",
        component: NbLoginComponent,
      },
      {
        path: "register",
        component: NbRegisterComponent,
      },
      {
        path: "request-password",
        component: NbRequestPasswordComponent,
      },
      {
        path: "logout",
        component: NbLogoutComponent,
      },
      {
        path: "",
        redirectTo: "",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "",
    component: HomepageComponent,
    canActivate: [AuthGuardGuard, LoginauthGuard],
  },
  {
    path: "homepage",
    component: HomepageComponent,
    canActivate: [AuthGuardGuard, LoginauthGuard],
  },
  {
    path: "register",
    component: RegisterBusinessComponent,
    canActivate: [LoginauthGuard],
  },
  {
    path: "invite-register",
    component: InviteUserRegistrationComponent,
  },
  {
    path: "verify-email",
    component: ReVerificationComponent,
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [LoginauthGuard],
  },
  {
    path: "email-verification",
    component: VerifyEmailComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: "forget-password",
    component: ForgetPasswordComponent,
    canActivate: [LoginauthGuard],
  },
  {
    path: "signup-success",
    component: SignupSuccessComponent,
  },
  { path: "", redirectTo: "homepage", pathMatch: "full" },
  { path: "**", redirectTo: "homepage" },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
