import { Component, OnInit, TemplateRef } from "@angular/core";
import { ServiceService } from "../service.service";
import {ActivatedRoute, Router} from "@angular/router";
import { NbDialogService } from "@nebular/theme";
import { DomSanitizer } from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "ngx-invite-user-registration",
  templateUrl: "./invite-user-registration.component.html",
  styleUrls: ["./invite-user-registration.component.scss"],
})
export class InviteUserRegistrationComponent implements OnInit {
  email: any;
  group_id: any;
  password: any;
  localItem: any;
  privacyPolicyHtml: any;
  termsConditionHtml: any;
  URLHasMail: boolean = false;
  isAdminReg: Boolean = false;
  constructor(
    private service: ServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getLocalStorageData();
    if (location.search === "") {
      this.service.showToast("info", "", "Url is not valid");
      this.router.navigate([""]);
      return;
    }
    this.route.queryParams.subscribe(params => {
      if (params['group_id']) {
        if(params['email']){
          this.email = params['email'];
          this.URLHasMail = true;
        } else {
          this.email = '';
          this.URLHasMail = false
        }
        this.group_id = params['group_id'];
      } else if (params['admin']) {
        if (params['email']) {
          this.email = params['email'];
          this.URLHasMail = true;
        } else {
          this.email = '';
          this.URLHasMail = false
        }
        this.isAdminReg = true;
      } else {
        if(params['email']){
          this.email = params['email'];
          this.URLHasMail = true;
        } else {
          this.email = '';
          this.URLHasMail = false
        }
      }
    });
    // if (location.search.includes("&group_id")) {
    //   let split = location.search.split("&");
    //   this.email = split[0].split("=")[1];
    //   this.group_id = split[1].split("=")[1];
    // } else if (location.search.includes("&admin")) {
    //   let split = location.search.split("&");
    //   this.email = split[0].split("=")[1];
    //   this.isAdminReg = true;
    // } else {
    //   this.email = location.search.split("=")[1];
    // }

    this.spinner.show();
    this.service.getSiteSetting().subscribe(
      (res) => {
        if (res.success) {
          this.privacyPolicyHtml = res.data.filter(
            (e) =>e._id === "642135e677ea22a113b68556"
          )[0]?.data;
          this.termsConditionHtml = res.data.filter(
            (e) =>  e._id === "6421374277ea22a113b68557"
          )[0]?.data;
          this.privacyPolicyHtml = this.privacyPolicyHtml
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&amp;/g, "&");
          this.termsConditionHtml = this.termsConditionHtml
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&amp;/g, "&");
          this.privacyPolicyHtml = this.sanitizer.bypassSecurityTrustHtml(
            this.privacyPolicyHtml
          );
          this.termsConditionHtml = this.sanitizer.bypassSecurityTrustHtml(
            this.termsConditionHtml
          );
        }
        this.spinner.hide();
      },
      () => this.spinner.hide()
    );
  }

  getLocalStorageData() {
    let parse = JSON.parse(localStorage.getItem("register-azui-item"));
    if (parse === null) {
      this.localItem = { role: "", status: "" };
      return;
    }
    this.localItem = parse;
  }

  selected(value) {
    this.localItem.role === value;
  }

  register(value) {
    value.role = "business";

    if (this.isAdminReg) {
      value.status = "Sub-Admin";
    } else {
      value.status = "Sub";
    }
    console.log("value", value);
    this.spinner.show();
    this.service.registerAsAInvite(value).subscribe(
      (res) => {
        if (res.success) {
          this.service.showToast("primary", "", res.message);
          setTimeout(() => {
            this.router.navigate([""]);
          }, 2000);
        }
        this.spinner.hide();
      },
      () => this.spinner.hide()
    );
  }

  openModel(contentTemplate: TemplateRef<any>) {
    this.dialogService.open(contentTemplate, {});
  }
}
